import React, { useCallback, useEffect } from 'react';
import './App.css';
import { Card, Layout, Typography, Row, Col, Button, Spin } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom'

import { useContext } from 'react';
import { store } from './store';

import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { Auth, API } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import '@aws-amplify/ui/dist/style.css';

const { Content, Footer } = Layout;
const { Title } = Typography;
const { Meta } = Card;

I18n.putVocabulariesForLanguage("ja", {
  [Translations.USERNAME_LABEL]: "ユーザID",
  [Translations.USERNAME_PLACEHOLDER]: "ユーザIDを入力してください",
  [Translations.PASSWORD_LABEL]: "パスワード",
  [Translations.PASSWORD_PLACEHOLDER]: "パスワードを入力してください",
  [Translations.FORGOT_PASSWORD_TEXT]: " ",
  [Translations.RESET_PASSWORD_TEXT]: " ",
  [Translations.SIGN_OUT]: "ログアウト",
  'Username cannot be empty': 'ユーザIDを入力してください',
  'User does not exist.': 'ユーザが存在しません',
  'Incorrect username or password.': 'ユーザIDまたはパスワードが違います',
  'User is not confirmed.': 'ユーザは承認されていません',
  'Username/client id combination not found.': 'ユーザIDとクライアントIDの組み合わせが存在しません',
  'Password attempts exceeded': 'パスワード試行回数が超過しました',
  'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
  // 'Custom auth lambda trigger is not configured for the user pool.': 'ユーザ名とパスワードを正しく入力してください',
});
I18n.setLanguage('ja');

function MyBids(props) {
  const { state, dispatch } = useContext(store);

  const history = useHistory();
  const handleBack = useCallback(() => history.goBack(), [history]);

  // for GTag
  useEffect(() => {
    document.title = `自分の最高入札商品ページ - 国府小バザー`;
    window.gtag('config', 'G-HY7WMG3R9D', { 'page_path': props.location.pathname });
  }, [props.location.pathname]);

  useEffect(() => {
    getName();
    getMyBids();
  }, []);

  const getName  = async () => {
    console.log('Starting getName...')
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      dispatch({ type: "SET_NAME", payload: { name: currentAuthenticatedUser.attributes.name, username: currentAuthenticatedUser.username }});
    } catch (e) {
      dispatch({ type: "SET_NAME", payload: { name: null, username: null }});
    }
  };

  const getMyBids = async () => {
    console.log('Starting getMyBids...')
    if (!state.username) {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (e) {
        console.log('Not yet signin')
        return;
      }
    }
    dispatch({ type: "SET_ON_SUBMITTING", payload: true });
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
    const apiName = 'BazaarAuctionApi';
    const path = '/mybids';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Bazaar-Token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Bazaar-Username': currentAuthenticatedUser.username,
      },
    };
    API.get(apiName, path, myInit)
    .then(async (response) => {
      console.log('myBids=');
      console.log(response);
      dispatch({ type: "SET_MYBIDS", payload: response.mybids });
      dispatch({ type: "SET_ON_SUBMITTING", payload: false });
    })
    .catch(err => {
      console.log(err);
      alert(err);
    });
  };

  const afterSignin = async () => {
    getName();
    getMyBids();
  };

  const marginTop = {
    marginTop: "3rem"
  }

  return (
    <div className="MyBids">
      <AmplifyAuthenticator>
        {/* <AmplifySignOut/> */}
        <AmplifySignOut
          buttonText={ "ログアウトする（" + state.name + "さん）"}
          handleAuthStateChange={getName}
          />
        <AmplifySignIn
          headerText="ログインしてください"
          slot="sign-in"
          hideSignUp="true"
          hideForgotPassword="true"
          submitButtonText="ログイン"
          handleAuthStateChange={afterSignin}
          />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true,
            },
            {
              type: "password",
              required: false,
            },
          ]}
        />
        <Layout className="layout">
          <Content>
            <Title className="main-title">国府小バザー特設ページ</Title>
            <Title className="sub-title" level={2}>最高入札商品</Title>
            <div className="site-layout-content">
            <Spin tip="Loading..." spinning={state.onSubmitting}>
              <Row className="items">
                { state.myBids.map(d => {
                  return (
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} key={d.id}>
                      <Link to={"/products/" + d.id}>
                        <Card hoverable cover={
                          <img alt={d.name} src={"/thumbnails/" + d.id + ".jpg"} onError={(e) => e.target.src = '/images/notfound.png'}/>
                        }>
                          <Meta title={d.id + ' ' + state.products.filter(p => p.id === d.id)[0]['name']} description={'入札額 ' + d.price + '円'} className="highest-bids" />
                        </Card>
                      </Link>
                    </Col>
                  );
                }) }
                </Row>
              </Spin>
              <div className="back-to" style={marginTop}><Button icon={<LeftCircleFilled/>} onClick={handleBack}>戻る</Button></div>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Kokufu Bazaar Auction ©2020-2021 Created by Kokufu PTA</Footer>
        </Layout>
      </AmplifyAuthenticator>
    </div>
  );
}

export default MyBids;
