import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import './App.css';
import { BackTop, Carousel, Layout, Row, Col, Card } from 'antd';
import { useContext } from 'react';
import { store } from './store';
import bgimage from './kokufu.jpg';

const { Content, Footer } = Layout;
const { Meta } = Card;

function Random(props) {
  const { state } = useContext(store);

  // for GTag
  useEffect(() => {
    document.title = `ホーム - 国府小バザー`;
    // window.gtag(props.location.pathname);
    window.gtag('config', 'G-HY7WMG3R9D', { 'page_path': props.location.pathname });
  }, [props.location.pathname]);

  const atari = (() => {
    if (Math.floor( Math.random() * 10 ) === 1) {
      return true;
    } else {
      return false;
    }
  });

  const carouselStyle = {
    height: '360px',
    color: '#fff',
    lineHeight: '60px',
    textAlign: 'center',
    backgroundColor: '#364d79',
    backgroundImage: `url(${bgimage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundPositionY: 'top',
  };

  return (
    <div className="Random">
      <Layout className="layout">
        <Content>
          <Carousel>
            <div>
              <h3 style={carouselStyle}>国府小バザー特設ページ</h3>
            </div>
          </Carousel>
          {/* <Title className="main-title">国府小バザー特設ページ</Title> */}
          <div className="site-layout-content">
            <div className="link-to-information"><Link to="/Information">必ず読んでからバザーに参加してください</Link></div>
            <ul className="categories">
              { state.categories.map((c, i) => {
                return (
                  <li key={`category${i}`}><Link to={c.link}>{c.name}</Link></li>
                );
              })}
            </ul>
            <div className="link-to-mybids"><Link to="/mybids">自分の最高入札商品を表示する</Link></div>

            <Row className="items">
              { state.products.filter(atari).map(d => {
                return (
                  <Col xs={24} sm={12} md={8} lg={6} xl={4} key={d.id}>
                    <Link to={"/products/" + d.id}>
                      <Card hoverable cover={
                        <img alt={d.name} src={"/images/" + d.id + ".jpg"} onError={(e) => e.target.src = '/images/notfound.png'}/>
                      }>
                        <Meta title={d.id + ' ' + d.name} description={d.info} />
                      </Card>
                    </Link>
                  </Col>
                );
              }) }
            </Row>
          </div>
        </Content>
        <BackTop />
        <Footer style={{ textAlign: 'center' }}>Kokufu Bazaar Auction ©2020-2021 Created by Kokufu PTA</Footer>
      </Layout>
    </div>
  );
}

export default Random;
