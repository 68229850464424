import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import './App.css';
import { BackTop, Carousel, Layout, Row, Col, Card, Spin } from 'antd';
import { useContext } from 'react';
import { store } from './store';
import bgimage from './kokufu.jpg';

import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { Auth, API } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import '@aws-amplify/ui/dist/style.css';

const { Content, Footer } = Layout;
const { Meta } = Card;

I18n.putVocabulariesForLanguage("ja", {
  [Translations.USERNAME_LABEL]: "ユーザID",
  [Translations.USERNAME_PLACEHOLDER]: "ユーザIDを入力してください",
  [Translations.PASSWORD_LABEL]: "パスワード",
  [Translations.PASSWORD_PLACEHOLDER]: "パスワードを入力してください",
  [Translations.FORGOT_PASSWORD_TEXT]: " ",
  [Translations.RESET_PASSWORD_TEXT]: " ",
  [Translations.SIGN_OUT]: "ログアウト",
  'Username cannot be empty': 'ユーザIDを入力してください',
  'User does not exist.': 'ユーザが存在しません',
  'Incorrect username or password.': 'ユーザIDまたはパスワードが違います',
  'User is not confirmed.': 'ユーザは承認されていません',
  'Username/client id combination not found.': 'ユーザIDとクライアントIDの組み合わせが存在しません',
  'Password attempts exceeded': 'パスワード試行回数が超過しました',
  'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
  // 'Custom auth lambda trigger is not configured for the user pool.': 'ユーザ名とパスワードを正しく入力してください',
});
I18n.setLanguage('ja');

function NoBids(props) {
  const { state, dispatch } = useContext(store);

  // for GTag
  useEffect(() => {
    document.title = `未入札商品 - 国府小バザー`;
    window.gtag('config', 'G-HY7WMG3R9D', { 'page_path': props.location.pathname });
  }, [props.location.pathname]);

  useEffect(() => {
    getName();
    getNoBids();
  }, []);

  const getName  = async () => {
    console.log('Starting getName...')
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      dispatch({ type: "SET_NAME", payload: { name: currentAuthenticatedUser.attributes.name, username: currentAuthenticatedUser.username }});
    } catch (e) {
      dispatch({ type: "SET_NAME", payload: { name: null, username: null }});
    }
  };

  const getNoBids = async () => {
    console.log('Starting getNoBids...')
    if (!state.username) {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (e) {
        console.log('Not yet signin')
        return;
      }
    }
    dispatch({ type: "SET_ON_SUBMITTING", payload: true });
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
    const apiName = 'BazaarAuctionApi';
    const path = '/bids';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Bazaar-Token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Bazaar-Username': currentAuthenticatedUser.username,
      },
    };
    API.get(apiName, path, myInit)
    .then(async (response) => {
      console.log('response.bidsList=====')
      console.log(response.bidsList)
      dispatch({ type: "SET_PRODUCTS_PLACED_BID", payload: response.bidsList });
      dispatch({ type: "SET_ON_SUBMITTING", payload: false });
    })
    .catch(err => {
      console.log(err);
      alert(err);
    });
  };

  const afterSignin = async () => {
    getName();
    getNoBids();
  };

  const carouselStyle = {
    height: '360px',
    color: '#fff',
    lineHeight: '60px',
    textAlign: 'center',
    backgroundColor: '#364d79',
    backgroundImage: `url(${bgimage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundPositionY: 'top',
  };

  return (
    <div className="Random">
      <AmplifyAuthenticator>
        {/* <AmplifySignOut/> */}
        {/* <AmplifySignOut
          buttonText={ "ログアウトする（" + state.name + "さん）"}
          handleAuthStateChange={getName}
          /> */}
        <AmplifySignIn
          headerText="ログインしてください"
          slot="sign-in"
          hideSignUp="true"
          hideForgotPassword="true"
          submitButtonText="ログイン"
          handleAuthStateChange={afterSignin}
          />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true,
            },
            {
              type: "password",
              required: false,
            },
          ]}
        />
        <Layout className="layout">
        <Content>
          <Carousel>
            <div>
              <h3 style={carouselStyle}>国府小バザー特設ページ</h3>
            </div>
          </Carousel>
          {/* <Title className="main-title">国府小バザー特設ページ</Title> */}
          <div className="site-layout-content">
            <div className="link-to-information"><Link to="/Information">必ず読んでからバザーに参加してください</Link></div>
            <ul className="categories">
              { state.categories.map((c, i) => {
                return (
                  <li key={`category${i}`}><Link to={c.link}>{c.name}</Link></li>
                );
              })}
            </ul>
            <div className="link-to-mybids"><Link to="/mybids">自分の最高入札商品を表示する</Link></div>

            <Spin tip="Loading..." spinning={state.onSubmitting}>
            <Row className="items">
              { state.products.filter(pp => state.productsPlacedBid.indexOf(pp.id) === -1).map(d => {
                return (
                  <Col xs={24} sm={12} md={8} lg={6} xl={4} key={d.id}>
                    <Link to={"/products/" + d.id}>
                      <Card hoverable cover={
                        <img alt={d.name} src={"/images/" + d.id + ".jpg"} onError={(e) => e.target.src = '/images/notfound.png'}/>
                      }>
                        <Meta title={d.id + ' ' + d.name} description={d.info} />
                      </Card>
                    </Link>
                  </Col>
                );
              }) }
            </Row>
            </Spin>
          </div>
        </Content>
        <BackTop />
        <Footer style={{ textAlign: 'center' }}>Kokufu Bazaar Auction ©2020-2021 Created by Kokufu PTA</Footer>
      </Layout>
      </AmplifyAuthenticator>
    </div>
  );
}

export default NoBids;
