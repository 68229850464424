import React, { useCallback, useEffect } from 'react';
import './App.css';
import { List, Layout, Typography, Row, Col, Button } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

const { Content, Footer } = Layout;
const { Title } = Typography;

// 固定データ
const information = [
  '当バザーオークションはPTAによる運営となっていますので、使い方や内容についての問い合わせを国府小学校に対して行わないでください。',
  '古いパソコンや特殊なスマートフォンなど、ご利用環境によってはうまく動作しない場合があります。あらかじめご了承ください。',
  '入札の際にはお手紙に記載してあります、ユーザID、個人パスワードをご入力ください。',
  'たくさんの方にご参加いただけるように即決価格の設定はありませんので、購入価格にご注意ください。',
  '児童が参加する場合は、必ず保護者の方の責任のもとご参加くださいますようお願いいたします。',
  '出品物には長期保管による劣化や、傷あり等の商品が含まれている場合もあります。',
  '一部写真に載っていない商品がおまけで追加されている場合があります。',
  'バザーオークションの終了は2021年2月7日(日)の21時です。時間は少し後ろにずれる可能性がありますのでご了承ください。',
  '今回のバザーオークションの趣旨をご理解の上、ノークレーム・ノーリターンに賛同していただける方のみご参加くださいますようお願いします。',
  ];

function Information(props) {
  const history = useHistory();
  const handleBack = useCallback(() => history.goBack(), [history]);

  // for GTag
  useEffect(() => {
    document.title = `はじめにお読みください - 国府小バザー`;
    window.gtag('config', 'G-HY7WMG3R9D', { 'page_path': props.location.pathname });
  }, [props.location.pathname]);

  const mockList = {
    borderTop: "1px solid #eee",
    padding: "5px 0",
    paddingTop: "2rem",
    textAlign: "left"
  }
  const bigger = {
    fontSize: "1.1rem",
  };

  return (
    <div className="Information">
      <Layout className="layout">
        <Content>
          <Title className="main-title">国府小バザー特設ページ</Title>
          <div className="site-layout-content">
            <div className="text-center">
              <Title className="readme" level={2}>はじめにお読みください</Title>
            </div>
            <Row className="attention">
              <Col span={24}>
                <List
                  dataSource={information}
                  renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                  )}
                />
              </Col>
              <div style={mockList}>入札に参加された方は自動的に抽選の対象となり、当選すると賞品が当たります。抽選への協賛企業は、<span style={bigger}>国府地区自主防災連合会様</span>、<span style={bigger}>尼料理 ししくい様</span>、阿波ダイニング シン坊様、くしや様、居酒屋大翔様、セブンイレブン国府府中店様、サンマック株式会社様、瀬畑  俊夫様、ケーメード様、鶏笑様、となります。ご協力いただいた企業のみなさま、ありがとうございます。</div>
            </Row>
            <div className="back-to"><Button icon={<LeftCircleFilled/>} onClick={handleBack}>戻る</Button></div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Kokufu Bazaar Auction ©2020-2021 Created by Kokufu PTA</Footer>
      </Layout>
    </div>
  );
}

export default Information;
