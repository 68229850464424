import React from 'react';
import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { store } from './store';
import Products from './Products';
import Home from './Home';
import Information from './Information';
import Random from './Random';
import MyBids from './MyBids';
import NoBids from './NoBids';
import './App.css';

// 固定データ
import categories from './data/categories.json';
import items from './data/products.json';

function App() {
  const { dispatch } = useContext(store);

  useEffect(() => {
    dispatch({ type: "SET_PRODUCTS", payload: items });
    dispatch({ type: "SET_CATEGORIES", payload: categories });
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <Route exact path='/' component={Random}/>
        <Route exact path='/information' component={Information}/>
        <Route path='/all' component={Home}/>
        <Route path='/category/:category' component={Home}/>
        <Route path='/products/:id' component={Products}/>
        <Route path='/mybids' component={MyBids}/>
        <Route path='/nobids' component={NoBids}/>
      </Router>
    </div>
  );
}

export default App;
