// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './store';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_sHI2dOMLz",
    userPoolWebClientId: "33rsr9cjm0mgd1g2gp3poorqjm",
    identityPoolId: "ap-northeast-1:36433cb9-05db-435b-863a-33b0c1fdd26a",
  },
  API: {
    endpoints: [
      {
        name: "BazaarAuctionApi",
        // endpoint: "http://localhost:5000",
        endpoint: "https://c5dd5iurpb.execute-api.ap-northeast-1.amazonaws.com/production",
        region: "ap-northeast-1",
      }
    ]
  }
});

// // Amazon Cognito 認証情報プロバイダーを初期化します
// AWS.config.region = 'ap-northeast-1'; // リージョン
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: 'ap-northeast-1:36433cb9-05db-435b-863a-33b0c1fdd26a',
// });

// process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
  // <React.StrictMode>
    <StateProvider>
      <App />
    </StateProvider>,
  // </React.StrictMode>,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
