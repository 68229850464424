import React, { createContext, useReducer } from 'react';

const initialState = {
  count: 0,
  username: null,
  name: null,
  products: [],
  prices: {},
  categories: [],
  onSubmitting: false,
  onRefreshing: false,
  beingDisable: false,
  bidPrice: 0,
  refreshPricesesAt: null,
  isClosed: false,
  myBids: [],
  productsPlacedBid: [],
  refreshMyBidsAt: null,
};

const store = createContext(initialState);

// Providerの定義
const { Provider } = store;
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'INCREMENT_COUNT':
        return { ...state, count: state.count + 1 }
      case 'DECREMENT_COUNT':
        return { ...state, count: state.count - 1 }
      case 'SET_NAME':
        return { ...state, name: action.payload.name, username: action.payload.username }
      case 'SET_PRODUCTS':
        return { ...state, products: action.payload }
      case 'SET_PRICES':
        return { ...state, prices: action.payload, refreshPricesesAt: new Date() }
      case 'SET_PRICE':
        const id = action.payload.id;
        const price = action.payload.prices[id];
        state.prices[id] = price;
        return {
          ...state,
          prices: state.prices,
          refreshPricesesAt: new Date()
        }
      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload }
      case 'SET_ON_SUBMITTING':
        return { ...state, onSubmitting: action.payload, beingDisabled: action.payload }
      case 'SET_ON_REFRESHING':
        return { ...state, onRefreshing: action.payload, beingDisabled: action.payload }
      case 'SET_BID_PRICE':
        return { ...state, bidPrice: action.payload }
      case 'SET_LAST_REFRESH':
        return { ...state, refreshPricesesAt: action.payload }
      case 'CLOSE_BAZAAR':
        return { ...state, isClosed: action.payload }
      case 'SET_MYBIDS':
        return { ...state, myBids: action.payload, refreshMyBidsAt: new Date() }
      case 'SET_PRODUCTS_PLACED_BID':
        return { ...state, productsPlacedBid: action.payload }
      default:
        throw new Error();
      };
    }, initialState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }
