import React, { useCallback, useEffect } from 'react';
import './App.css';
import { Divider, Modal, message, Layout, Typography, Row, Col, Image, Select, Button } from 'antd';
import { ReloadOutlined, LoadingOutlined, LeftCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

import { useContext } from 'react';
import { store } from './store';

import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { Auth, API } from 'aws-amplify';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import '@aws-amplify/ui/dist/style.css';

const { Content, Footer } = Layout;
const { Title } = Typography;
// const { Meta } = Card;
const { Option } = Select;

I18n.putVocabulariesForLanguage("ja", {
  [Translations.USERNAME_LABEL]: "ユーザID",
  [Translations.USERNAME_PLACEHOLDER]: "ユーザIDを入力してください",
  [Translations.PASSWORD_LABEL]: "パスワード",
  [Translations.PASSWORD_PLACEHOLDER]: "パスワードを入力してください",
  [Translations.FORGOT_PASSWORD_TEXT]: " ",
  [Translations.RESET_PASSWORD_TEXT]: " ",
  [Translations.SIGN_OUT]: "ログアウト",
  'Username cannot be empty': 'ユーザIDを入力してください',
  'User does not exist.': 'ユーザが存在しません',
  'Incorrect username or password.': 'ユーザIDまたはパスワードが違います',
  'User is not confirmed.': 'ユーザは承認されていません',
  'Username/client id combination not found.': 'ユーザIDとクライアントIDの組み合わせが存在しません',
  'Password attempts exceeded': 'パスワード試行回数が超過しました',
  'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
  // 'Custom auth lambda trigger is not configured for the user pool.': 'ユーザ名とパスワードを正しく入力してください',
});
I18n.setLanguage('ja');

let id;

function Products(props) {
  const { state, dispatch } = useContext(store);

  const history = useHistory();
  const handleBack = useCallback(() => history.goBack(), [history]);

  // useEffect(() => {
  //   const unlisten = listen((location) => {
  //     if (!window.gtag) return
  //     if (!trackingId) {
  //       console.log(
  //         'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
  //       )
  //       return
  //     }
  //     window.gtag('config', trackingId, { page_path: location.pathname })
  //   })
  //   return unlisten
  // }, [trackingId, listen])

  // for GTag
  useEffect(() => {
    document.title = `商品ページ - 国府小バザー`;
    window.gtag('config', 'G-HY7WMG3R9D', { 'page_path': props.location.pathname });
  }, [props.location.pathname]);

  useEffect(() => {
    dispatch({ type: "SET_BID_PRICE", payload: 0 });

    if (!Object.keys(state.prices).length || !(id in state.prices) || state.prices[id] === undefined || shouldBeRefresh(60)) {
      getPrice();
    }

    // 200件を超えると全部の価格取得が遅いので外す 2021
    // if (state.name) {
    //   if (Object.keys(state.prices).length < 2 || shouldBeRefresh(600)) {
    //     console.log('Start fetching all prices...')
    //     getPrices();
    //   }
    // }
    getName();
  }, []);

  if (props.match && props.match.params && props.match.params.id) {
    id = props.match.params.id
  }

  function handlePriceChange(value) {
    if (value == state.bidPrice) return;
    // console.log(`${value}が選択されました`);
    dispatch({ type: "SET_BID_PRICE", payload: value });
  }

  const [name, notes] = (() => {
    if (state.products) {
      const pts = state.products.filter(p => p.id == id)
      if (pts.length > 0 && pts[0].name) {
        return [pts[0].name, pts[0].notes]
      } else {
        return ''
      }
    }
  })();

  const [price, highestBidder, priceElement] = (() => {
    if (Object.keys(state.prices).length) {
      if (state.prices && id in state.prices && state.prices[id] !== undefined && state.prices[id].price) {
        if (state.username == state.prices[id].username) {
          return [state.prices[id].price, 'あなた', state.prices[id].price];
        }
        return [state.prices[id].price, state.prices[id].name + 'さん', state.prices[id].price];
      } else if (state.products && state.products.length > 0) {
        const pts = state.products.filter(p => p.id == id)
        if (pts.length > 0 && pts[0].price) {
          return [pts[0].price, null, pts[0].price];
        }
      }
      return [0, null, 0];
    } else {
      // return [0, null];
      return [0, null, <LoadingOutlined />]
    }
  })();

  const buttonLabel = (() => {
    if (state.isClosed) {
      return '締め切りました';
    } else {
      return '入札する';
    }
  })();

  const getName  = async () => {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      dispatch({ type: "SET_NAME", payload: { name: currentAuthenticatedUser.attributes.name, username: currentAuthenticatedUser.username }});
    } catch (e) {
      dispatch({ type: "SET_NAME", payload: { name: null, username: null }});
    }
  };

  const getPrice = async () => {
    console.log('Starting getPrice...')
    if (!state.name) {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (e) {
        console.log('Not yet signin')
        return;
      }
    }
    const apiName = 'BazaarAuctionApi';
    const path = '/bid/' + id;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    API.get(apiName, path, myInit)
    .then(async (response) => {
      console.log('received price');
      console.log(response);
      dispatch({ type: "SET_PRICE", payload: { id, prices: response.products } });
      dispatch({ type: "CLOSE_BAZAAR", payload: response.isClosed });
    })
    .catch(err => {
      console.log(err);
      alert(err);
    });
  };

  const refreshPrice = async () => {
    dispatch({ type: "SET_ON_REFRESHING", payload: true });
    await getPrice();
    message.success('現在の価格を最新状態に更新しました');
    setTimeout(() => { dispatch({ type: "SET_ON_REFRESHING", payload: false }); }, 5000);
  };

  // 200件を超えると全部の価格取得が遅いので外す 2021
  // const getPrices = async () => {
  //   if (!state.name) {
  //     await Auth.currentAuthenticatedUser();
  //   }
  //   const apiName = 'BazaarAuctionApi';
  //   const path = '/bids';
  //   const myInit = {
  //     headers: {
  //       Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  //     },
  //   };
  //   API.get(apiName, path, myInit)
  //   .then(async (response) => {
  //     console.log('getPrices=');
  //     console.log(response);
  //     dispatch({ type: "SET_PRICES", payload: response.products });
  //     dispatch({ type: "CLOSE_BAZAAR", payload: response.isClosed });
  //   })
  //   .catch(err => {
  //     console.log(err);
  //     alert(err);
  //   });
  // };

  const postBid = async () => {
    if (price >= state.bidPrice) {
      warning('現在の価格より大きい金額を選択してください');
      return;
    }
    dispatch({ type: "SET_ON_SUBMITTING", payload: true });
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
    const apiName = 'BazaarAuctionApi';
    const path = '/bid/' + id;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Bazaar-Token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Bazaar-Username': currentAuthenticatedUser.username,
      },
      body: {
        id: id,
        username: currentAuthenticatedUser.username,
        bidder: currentAuthenticatedUser.attributes.name,
        price: state.bidPrice,
      },
    };
    API.post(apiName, path, myInit)
    .then(async (response) => {
      console.log(response);
      await getPrice();
      if ('isHighest' in response && response.isHighest) {
        success('入札しました！あなたが最高入札者です！')
      } else {
        success('入札しましたが、最高入札者ではありません')
      }
      setTimeout(() => { dispatch({ type: "SET_ON_SUBMITTING", payload: false }); }, 5000);
    })
    .catch(err => {
      if (err.response && err.response.status === 409) {
        getPrice();
        warning('同額で入札済みの方がいます');
      } else if (err.response && err.response.status === 406) {
        warning('入札が受け付けられませんでした');
      } else {
        console.log(err);
        console.log(err.response);
        error(err.response, "エラーが発生しました");
      }
      setTimeout(() => { dispatch({ type: "SET_ON_SUBMITTING", payload: false }); }, 5000);
    });
  };

  const afterSignin = async () => {
    await getName();
    getPrice();
  };

  function shouldBeRefresh(seconds = 60) {
    const dt = new Date();
    // dt.setMinutes(now.getMinutes() - 15); // 15分経っていたら価格の自動更新
    // dt.setSeconds(dt.getSeconds() - 60); // 60秒経っていたら価格の自動更新
    dt.setSeconds(dt.getSeconds() - seconds); // seconds秒経っていたら価格の自動更新
    // console.log(`dt=${dt} > refreshPricesesAt=${state.refreshPricesesAt}`)
    if (!state.refreshPricesesAt || dt > state.refreshPricesesAt) {
      return true;
    }
    return false;
  }

  function success(message, title = '') {
    Modal.success({
      title: title,
      content: message,
    });
  }

  function error(message, title = '') {
    Modal.error({
      title: title,
      content: message,
    });
  }

  function warning(message, title = '') {
    Modal.warning({
      title: title,
      content: message,
    });
  }

  return (
    <div className="Products">
      <AmplifyAuthenticator>
        {/* <AmplifySignOut/> */}
        <AmplifySignOut
          buttonText={ "ログアウトする（" + state.name + "さん）"}
          handleAuthStateChange={getName}
          />
        <AmplifySignIn
          headerText="ログインしてください"
          slot="sign-in"
          hideSignUp="true"
          hideForgotPassword="true"
          submitButtonText="ログイン"
          handleAuthStateChange={afterSignin}
          // usernameAlias="ユーザ名"
          />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true,
            },
            {
              type: "password",
              required: false,
            },
          ]}
        />
        <Layout className="layout">
          <Content>
            <Title className="main-title">国府小バザー特設ページ</Title>
            <div className="site-layout-content">
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Image src={"/images/" + id + ".jpg"} fallback='/images/notfound.png' />
                  <Row gutter={16} className="mt-16">
                    <Col span={8}>
                      <Image src={"/images/" + id + "_1.jpg"} fallback='/images/notfound.png'/>
                    </Col>
                    <Col span={8}>
                      <Image src={"/images/" + id + "_2.jpg"} fallback='/images/notfound.png'/>
                    </Col>
                    <Col span={8}>
                      <Image src={"/images/" + id + "_3.jpg"} fallback='/images/notfound.png'/>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={12}>
                  <Title className="product-name" level={3}>{ id + '. ' + name }</Title>
                  <div className="price">現在の価格<span>{ priceElement}</span>円{ highestBidder && <div className="highest">（最高入札者は<span>{ highestBidder }</span>です）</div> }</div>
                  <Select defaultValue="0" onChange={handlePriceChange}>
                    <Option value="0">選択してください</Option>
                    {(() => {
                        const items = [];
                        for(let ii=1;ii<=10;ii++){
                          items.push(<Option value={ price + ii * 10 } key={ii}>{ price + ii * 10 }円</Option>)
                        }
                        return items;
                    })()}
                  </Select>
                  <Button className="bid" type="primary" loading={state.onSubmitting} onClick={postBid} disabled={state.beingDisabled || state.isClosed}>{ buttonLabel }</Button>
                  <Button className="bid" icon={<ReloadOutlined/>} loading={state.onRefreshing} onClick={refreshPrice} disabled={state.beingDisabled}/>
                  <Divider className="notes-divider" orientation="left">補足事項</Divider>
                  <div className="notes">{ notes }</div>

                </Col>
              </Row>
              <div className="back-to"><Button icon={<LeftCircleFilled/>} onClick={handleBack}>戻る</Button></div>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Kokufu Bazaar Auction ©2020-2021 Created by Kokufu PTA</Footer>
        </Layout>
      </AmplifyAuthenticator>
    </div>
  );
}

export default Products;
